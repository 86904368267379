<template>
  <NotificationTransition>
    <NotificationBody
      v-if="show"
      :display="data.display"
      :duration="data.duration"
      class="max-w-sm overflow-hidden"
      @expired="closeNotification"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0 place-self-center">
            <CheckCircleIcon
              v-if="data.display === NotificationDisplay.SUCCESS"
              aria-hidden="true"
              class="h-6 w-6 text-green-400"
            />
            <ExclamationCircleIcon
              v-else-if="data.display === NotificationDisplay.WARNING"
              aria-hidden="true"
              class="h-6 w-6 text-orange-400"
            />
            <XCircleIcon
              v-else-if="data.display === NotificationDisplay.ERROR"
              aria-hidden="true"
              class="h-6 w-6 text-red-400"
            />
            <InformationCircleIcon
              v-else-if="data.display === NotificationDisplay.INFO"
              aria-hidden="true"
              class="h-6 w-6 text-blue-400"
            />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">{{ data.title }}</p>
            <p class="mt-1 text-sm text-gray-500">
              {{ data.body }}
            </p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <CloseButton @click="closeNotification" />
          </div>
        </div>
      </div>
    </NotificationBody>
  </NotificationTransition>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/vue/24/outline'
import type { SimpleNotificationData } from '@/components/notifications/types'
import { NotificationDisplay } from '@/components/notifications/types'
import NotificationTransition from '@/components/transitions/NotificationTransition.vue'
import CloseButton from '@/components/notifications/shared/CloseButton.vue'
import NotificationBody from '@/components/notifications/shared/NotificationBody.vue'

interface NotificationProps {
  data: SimpleNotificationData
}

defineProps<NotificationProps>()
const show = ref(true)

function closeNotification(): void {
  show.value = false
}
</script>
