import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuthStore } from '@/stores/auth'
import { RouteNames } from '@/router/RouteNames'
import { useCmsStore } from '@/stores/cms/CmsStore'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  const isAuthenticated = authStore.isAuthenticated
  const toLogin = to.name === RouteNames.login
  const toRoot = to.name === RouteNames.root
  if (isAuthenticated && (toLogin || toRoot || to.name === undefined)) {
    // Catch all for authenticated users which lead to an undefined route
    const cmsStore = useCmsStore()
    const customerPages = await cmsStore.fetchCustomerPages()

    let postLoginRoute = RouteNames.products
    if (customerPages.landing_page) {
      postLoginRoute = RouteNames.home
    }

    next({ name: postLoginRoute })
  } else if (to.meta.public || isAuthenticated) {
    // Allow access to public pages and authenticated pages
    next()
  } else {
    // Deny access to protected pages
    next({ name: RouteNames.login })
  }
})

export default router
