<template>
  <NotificationTransition>
    <NotificationBody
      :display="data.display"
      :duration="data.duration"
      class="max-w-sm overflow-hidden"
      @expired="onClick"
      v-if="show"
    >
      <div class="p-4">
        <div class="flex items-center">
          <div class="flex w-0 flex-1 justify-between">
            <p class="w-0 flex-1 text-sm font-medium text-gray-900">
              {{ data.title }}
            </p>
            <button
              v-if="data.button"
              type="button"
              class="ml-3 flex-shrink-0 rounded-md text-sm font-medium text-teal-500 hover:text-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-700"
              @click="onClick"
            >
              {{ data.button.text }}
            </button>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <CloseButton @click="onClick" />
          </div>
        </div>
      </div>
    </NotificationBody>
  </NotificationTransition>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { CondensedNotificationData } from "@/components/notifications/types";
import NotificationTransition from "@/components/transitions/NotificationTransition.vue";
import CloseButton from "@/components/notifications/shared/CloseButton.vue";
import NotificationBody from "@/components/notifications/shared/NotificationBody.vue";

interface NotificationProps {
  data: CondensedNotificationData;
}

const props = defineProps<NotificationProps>();
const show = ref(true);

async function onClick() {
  await props.data.button?.onClick();
  closeNotification();
}

function closeNotification(): void {
  show.value = false;
}
</script>
