export enum NotificationDisplay {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
}

type BaseNotification = {
  title: string;
  display: NotificationDisplay;
  duration?: number;
};

export type ActionButton = {
  text: string;
  onClick: () => void | Promise<void>;
};

export type SimpleNotificationData = BaseNotification & {
  body: string;
};

export type CondensedNotificationData = BaseNotification & {
  button?: ActionButton | null;
};
