<template>
  <div
    class="pointer-events-auto w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <slot />
    <div v-if="remainingTime > 0" class="h-1 w-full rounded-full bg-gray-200 dark:bg-gray-700">
      <div
        :class="[
          {
            'bg-ppgreen-500': display === NotificationDisplay.SUCCESS,
            'bg-yellow-500': display === NotificationDisplay.WARNING,
            'bg-red-500': display === NotificationDisplay.ERROR,
            'bg-blue-500': display === NotificationDisplay.INFO
          }
        ]"
        :style="`width: ${width}%`"
        class="h-full w-full rounded-full transition-all duration-100 ease-linear"
      ></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { NotificationDisplay } from '@/components/notifications/types'
import { ref } from 'vue'

interface NotificationProps {
  display: NotificationDisplay
  duration?: number
}

const emit = defineEmits<(e: 'expired') => void>()
const props = defineProps<NotificationProps>()
const width = ref(100)
const totalTime = props.duration ?? 0
let remainingTime = totalTime
const updateInterval = 100

function widthRemaining(): number {
  return (remainingTime / totalTime) * 100
}

if (remainingTime > 0) {
  const updateId = setInterval(() => {
    remainingTime -= updateInterval
    width.value = widthRemaining()
    if (remainingTime <= 0) {
      clearInterval(updateId)
      emit('expired')
    }
  }, updateInterval)
}
</script>
