import { defineStore } from "pinia";
import { ref } from "vue";
import type { Component } from "vue";
import SimpleNotification from "@/components/notifications/SimpleNotification.vue";
import CondensedNotification from "@/components/notifications/CondensedNotification.vue";
import type {
  CondensedNotificationData,
  SimpleNotificationData,
} from "@/components/notifications/types";

type Notification = {
  getTemplate: () => Component;
  data: any;
};

let notificationCount = 0;

export const useNotificationStore = defineStore("notifications", () => {
  const notifications = ref<Map<string, Notification>>(new Map());

  function addSimpleNotification(data: SimpleNotificationData): string {
    const key = notificationCount++ + "-SimpleNotification";
    notifications.value.set(key, {
      getTemplate: () => SimpleNotification,
      data: data,
    });
    return key;
  }

  function addCondensedNotification(data: CondensedNotificationData): string {
    const key = notificationCount++ + "-CondensedNotification";
    notifications.value.set(key, {
      getTemplate: () => CondensedNotification,
      data: data,
    });
    return key;
  }

  function removeNotification(key: string): boolean {
    return notifications.value.delete(key);
  }

  return {
    notifications,
    addSimpleNotification,
    addCondensedNotification,
    removeNotification,
  };
});
