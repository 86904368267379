<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <component
        :is="notification.getTemplate()"
        v-for="[key, notification] in notifications"
        :key="key"
        :data="notification.data"
        @after-leave="notificationStore.removeNotification(key)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useNotificationStore } from '@/stores/notifications'

const notificationStore = useNotificationStore()
const notifications = notificationStore.notifications
</script>
