import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationDisplay } from '@/components/notifications/types'
import Config from '@/config/Config'

export const useEasterEggStore = defineStore('paas-ei-winkel', () => {
  const konamiKeyCodes = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'KeyB',
    'KeyA',
    'Enter',
  ]
  const konamiCodePosition = ref<number>(0)
  const konamiCodeActive = computed(() => konamiCodePosition.value >= konamiKeyCodes.length)
  const notificationStore = useNotificationStore()
  const debugMode = Config.easterEggDebugMode

  function setKonamiCodeListener(element: HTMLElement) {
    element.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.code === konamiKeyCodes[konamiCodePosition.value]) {
        nextKonamiCodeNotification()
        konamiCodePosition.value++
      } else if (!konamiCodeActive.value && konamiCodePosition.value > 0) {
        konamiCodeIsResetNotification()
        konamiCodePosition.value = 0
      }
    })
  }

  function nextKonamiCodeNotification() {
    const nextCode = konamiKeyCodes[konamiCodePosition.value + 1]
    if (debugMode && nextCode) {
      notificationStore.addSimpleNotification({
        title: 'Easter egg',
        body: 'Entered: ' + konamiKeyCodes[konamiCodePosition.value] + ' next: ' + nextCode,
        duration: 2000,
        display: NotificationDisplay.INFO,
      })
    }
  }

  function konamiCodeIsResetNotification() {
    if (debugMode) {
      notificationStore.addSimpleNotification({
        title: 'Easter egg reset',
        body: 'Konami code is reset',
        duration: 1000,
        display: NotificationDisplay.WARNING,
      })
    }
  }

  function konamiCodeEnteredNotification() {
    notificationStore.addSimpleNotification({
      title: 'Easter egg',
      body: 'Konami code entered!',
      duration: 1000,
      display: NotificationDisplay.SUCCESS,
    })
  }

  function resetEasterEggs() {
    konamiCodePosition.value = 0
  }

  watch(konamiCodeActive, (value) => {
    if (value) {
      konamiCodeEnteredNotification()

      const elements = document.querySelectorAll('body')
      elements.forEach((element) => {
        element.classList.add('animate-drunken')
      })
    }
  })

  return { konamiCodeEntered: konamiCodeActive, setKonamiCodeListener, resetEasterEggs }
})
